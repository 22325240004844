import { StackPanel, AdvancedDynamicTexture, Button, Control, Image, TextBlock, Ellipse, Rectangle, Grid, Slider } from "@babylonjs/gui";
import { MeshBuilder, Vector3, Scene, Camera, Mesh, Texture, SSAO2RenderingPipeline, Engine } from "@babylonjs/core";

import { ImageWall } from "./ImageWall";
import { BasicScene } from "./BasicScene";

export class GUI {
  private scene: Scene;
  private camera: Camera;
  public guiTexture: AdvancedDynamicTexture;
  private instructionsPlane: Mesh | null = null;
  private canvas: HTMLCanvasElement;
  private imageWall: ImageWall;
  private link: Button;
  private controlElements: Control[] = [];
  private controlElementsChildren: Control[] = [];
  private shadowColor = "#000000b0"; // Schattenfarbe
  private shadowBlur = 7; // Weichheit des Schattens
  private shadowOffsetX = 7; // Horizontaler Versatz des Schattens
  private shadowOffsetY = 7;
  private cornerRadius = 7;
  private cubeIcons: Image[];
  private logo: Image;
  private currentScrollIndex = 0;
  private maxVisibleIcons = 4;
  private basicScene: BasicScene;
  private slider: StackPanel;
  private currentCubeType: number;
  private lastCubeType: number;
  private ssao: SSAO2RenderingPipeline | null = null;
  private engine: Engine;
  private interval: number | null = null; // Allgemeine Intervallvariable
  private actionStartTime: number | null = null;
  private baseSpeedMultiplier: number = 1;
  private speedScalingFactor: number = 20; // Steuert, wie schnell die Geschwindigkeit über die Zeit zunimmt
  private maxSpeedMultiplier: number = 100; // Begrenzung der maximalen Geschwindigkeit
  private scaleSlider: Slider; // Neuer Slider für die Skalierung

  constructor(scene: Scene, camera: Camera, canvas: HTMLCanvasElement, imageWall: ImageWall, basicScene: BasicScene, engine: Engine) {
    this.slider = new StackPanel();
    this.logo = new Image("logo", "logo.png");
    this.basicScene = basicScene;
    this.link = new Button("btn5");
    this.imageWall = imageWall;
    this.scaleSlider = new Slider();
    this.scene = scene;
    this.camera = camera;
    this.canvas = canvas;
    this.guiTexture = AdvancedDynamicTexture.CreateFullscreenUI("UI", true, this.scene, Texture.BILINEAR_SAMPLINGMODE, true);
    this.currentCubeType = 0;
    this.lastCubeType = 0;
    this.cubeIcons = [];
    this.engine = engine;

    document.addEventListener("fullscreenchange", () => {
      this.onFullscreenChange();
    });

    this.addGUIElements();
    this.initializeSSAO();
  }

  public addGUIElements() {
    let StartY = -28.0;
    const StartX = 60.0;
    const OffsetY = 60.0;
    const imageUploadInput = this.createImageUploadInput();
    const ButtonWidth = 400;
    const ButtonHeight = 36;
    const ButtonBorderSize = 1;
    const BackgroundColor = "#FFFFFF";
    const Color = "#000000";
    const Fontsize = 20;
    const H_Alignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    const V_Alignment = Control.VERTICAL_ALIGNMENT_TOP;

    this.controlElements.push(
      this.createButton("btn1", "Vollbild (an/aus)", 1, ButtonWidth, ButtonHeight, ButtonBorderSize, (StartY += OffsetY), StartX, Color, BackgroundColor, Fontsize, H_Alignment, V_Alignment, true)
    );
    this.controlElements.push(
      this.createButton(
        "btn2",
        "Lade ein Foto von deinem Garten hoch",
        2,
        ButtonWidth,
        ButtonHeight,
        ButtonBorderSize,
        (StartY += OffsetY),
        StartX,
        Color,
        BackgroundColor,
        Fontsize,
        H_Alignment,
        V_Alignment,
        true
      )
    );
    this.controlElements.push(
      this.createButton(
        "btn3",
        "Speichere deine Idee als ein Foto",
        3,
        ButtonWidth,
        ButtonHeight,
        ButtonBorderSize,
        (StartY += OffsetY),
        StartX,
        Color,
        BackgroundColor,
        Fontsize,
        H_Alignment,
        V_Alignment,
        true
      )
    );
    this.controlElements.push(
      this.createButton(
        "btn4",
        "Teile deine Idee mit Familie & Freunden ",
        4,
        ButtonWidth,
        ButtonHeight,
        ButtonBorderSize,
        (StartY += OffsetY),
        StartX,
        Color,
        BackgroundColor,
        Fontsize,
        H_Alignment,
        V_Alignment,
        true
      )
    );
    this.controlElements.push(
      this.createButton("btn5", "Anleitung", 1000, 400, ButtonHeight, ButtonBorderSize, (StartY += OffsetY), StartX, Color, "#FEDF8A", Fontsize, H_Alignment, V_Alignment, true)
    );
    this.controlElements.push(
      this.createButton("logotext", "", 1000, ButtonWidth, 60, 3, -32, -49.0, "#000000", "#FFFFFFFF", 60, Control.HORIZONTAL_ALIGNMENT_RIGHT, Control.VERTICAL_ALIGNMENT_BOTTOM, false)
    );
    this.controlElements.push(
      this.createButton(
        "link",
        "www.garden-cube.eu",
        2000,
        ButtonWidth,
        ButtonHeight,
        3,
        -32,
        StartX,
        "#000000",
        "#FFFFFFFF",
        26,
        Control.HORIZONTAL_ALIGNMENT_LEFT,
        Control.VERTICAL_ALIGNMENT_BOTTOM,
        true
      )
    );
    this.addSliderCubes();
    this.addScaleSlider(); // Hinzufügen des neuen Sliders

    this.controlElements[0].onPointerUpObservable.add(() => {
      this.toggleFullscreen();
    });

    this.controlElements[1].onPointerUpObservable.add(() => {
      imageUploadInput.click(); // Öffnet den Datei-Dialog, wenn der Button geklickt wird
    });

    this.controlElements[2].onPointerUpObservable.add(() => {
      //this.takeScreenShot();
      this.downloadCanvasAsPNG();
    });

    const Link = this.controlElements[6] as Button;
    Link.onPointerUpObservable.add(() => {
      window.open("https://garden-cube.eu");
    });

    const LogoText = this.controlElements[5] as Button;
    const textBlock = new TextBlock();
    textBlock.text = "www.garden-cube.eu";
    textBlock.fontSize = 32;
    textBlock.color = "#00A658";
    LogoText.addControl(textBlock);
    this.guiTexture.addControl(LogoText);

    this.controlElements[4].onPointerUpObservable.add(() => {
      this.toggleInstructions();
    });

    this.addLogo();
    this.addCubeControls();
  }

  private createButton(
    label: string,
    text: string,
    ButtonNr: number,
    buttonWidth: number,
    buttonHeight: number,
    buttonBorderSize: number,
    top: number,
    left: number,
    color: string,
    backgroundColor: string,
    fontsize: number,
    h_alignment: number,
    v_alignment: number,
    observable: boolean
  ): Button {
    const button = Button.CreateSimpleButton(label, text);

    //const button = Button.CreateImageButton(label,text,"GUI/button.svg")
    button.thickness = buttonBorderSize;
    button.fontSize = fontsize;
    button.zIndex = -1;
    button.width = buttonWidth + "px";
    button.height = buttonHeight + "px";
    //button.width =  buttonWidth * this.canvas.width / 1500 + "px";
    button.top = top + "px";
    button.left = left + 15 + "px";
    button.verticalAlignment = v_alignment;
    button.horizontalAlignment = h_alignment;
    button.color = color;
    button.background = backgroundColor;
    if (observable) {
      button.onPointerEnterObservable.add(() => {
        //button.background = "#FFFFFFFF"; // Dunklerer Hintergrund bei Hover
        button.scaleX = 1.2; // Leichte Vergrößerung in X-Richtung
        button.left = left + 40 + "px";
      });
      button.onPointerOutObservable.add(() => {
        // button.background = "#FEFEFE"; // Zurück zum ursprünglichen Hintergrund
        this.resetButton(button, left);
      });

      button.onPointerUpObservable.add(() => {
        // button.background = "#FEFEFE"; // Zurück zum ursprünglichen Hintergrund
        this.resetButton(button, left);
      });
    }

    // Kreisgröße und -position berechnen
    if (ButtonNr < 1000) {
      this.createCircleNumber(buttonHeight, left, top, ButtonNr, fontsize, color, h_alignment, v_alignment);
    }
    if (ButtonNr == 2000) {
      this.createCircleIcon(buttonHeight, left, top, "gui/left_arrow.png", color, h_alignment, v_alignment);
    }

    button.shadowColor = this.shadowColor;
    button.shadowBlur = this.shadowBlur;
    button.shadowOffsetX = this.shadowOffsetX;
    button.shadowOffsetY = this.shadowOffsetY;
    button.cornerRadius = this.cornerRadius;
    this.guiTexture.addControl(button); // Button der GUI-Textur hinzufügen
    button.name = label;
    return button;
  }

  private resetButton(button: Button, left: number) {
    button.scaleX = 1.0; // Zurück zur ursprünglichen Größe in X-Richtung
    button.scaleY = 1.0; // Zurück zur ursprünglichen Größe in Y-Richtung
    button.left = left + 17 + "px";
  }

  private createCircleNumber(buttonHeight: number, left: number, top: number, number: number, fontsize: number, color: string, h_alignment: number, v_alignment: number): TextBlock {
    const circle = this.createCircle(buttonHeight, left, top, color, h_alignment, v_alignment);
    const textBlock = new TextBlock();
    textBlock.text = "" + number;
    textBlock.fontSize = fontsize + 10;
    textBlock.color = color;
    circle.addControl(textBlock); // Textblock zum Kreis hinzufügen
    this.guiTexture.addControl(circle); // Kreis der GUI-Textur hinzufügen
    this.controlElementsChildren.push(circle);

    return textBlock;
  }

  private createCircleIcon(buttonHeight: number, left: number, top: number, imagePath: string, color: string, h_alignment: number, v_alignment: number): Image {
    const circle = this.createCircle(buttonHeight, left, top, color, h_alignment, v_alignment);
    const circleScale = 1.0;
    const circleDiameter = buttonHeight * circleScale;
    const imageSize = circleDiameter * 0.8; // Skalierung des Icons
    const image = new Image("gui/circleIcon.svg", imagePath);
    image.width = imageSize + "px";
    image.height = imageSize + "px";

    // Zentrieren des Icons im Kreis
    //image.left = (circleLeft + buttonWidth / 2) - imageSize / 2 + "px";
    //image.top = circleTop - imageSize / 2 + "px";

    circle.addControl(image); // Textblock zum Kreis hinzufügen
    this.guiTexture.addControl(circle); // Kreis der GUI-Textur hinzufügen
    this.controlElementsChildren.push(circle);

    return image;
  }

  private createCircle(buttonHeight: number, left: number, top: number, color: string, h_alignment: number, v_alignment: number): Ellipse {
    const circleScale = 1.5;
    const circleDiameter = buttonHeight * circleScale; // 150% der Button-Höhe für den Durchmesser
    const circle = new Ellipse();
    circle.verticalAlignment = v_alignment;
    circle.horizontalAlignment = h_alignment;
    circle.widthInPixels = circleDiameter;
    circle.heightInPixels = circleDiameter;
    circle.background = "#FEDF8A";
    circle.left = left - circleDiameter / 2;
    if (v_alignment == Control.VERTICAL_ALIGNMENT_TOP) circle.topInPixels = top - buttonHeight / 4;
    if (v_alignment == Control.VERTICAL_ALIGNMENT_BOTTOM) circle.topInPixels = top + buttonHeight / 4;
    circle.zIndex = 1; // Kreis hinter dem Button
    circle.thickness = 2;
    circle.color = color;
    return circle;
  }

  private addLogo() {
    this.logo.name = "logo";
    this.logo.horizontalAlignment = Image.HORIZONTAL_ALIGNMENT_RIGHT;
    this.logo.verticalAlignment = Image.VERTICAL_ALIGNMENT_BOTTOM;
    this.logo.width = "200px";
    this.logo.height = "200px";
    this.logo.left = "-32px";
    this.logo.top = "-100px";
    this.logo.shadowColor = this.shadowColor;
    this.logo.shadowBlur = this.shadowBlur;
    this.logo.shadowOffsetX = this.shadowOffsetX;
    this.logo.shadowOffsetY = this.shadowOffsetY;

    this.guiTexture.addControl(this.logo);
  }

  private toggleInstructions() {
    if (!this.instructionsPlane) {
      this.createInstructionsPlane();
    } else {
      this.instructionsPlane.isVisible = !this.instructionsPlane.isVisible;
    }
  }

  private createTextBlock(text: string, fontSize: number, height: number, top: number, advancedTexture: AdvancedDynamicTexture, v_alignment: number): TextBlock {
    const textBlock = new TextBlock();
    textBlock.text = text;
    textBlock.color = "white";
    textBlock.fontSize = fontSize + "px";
    textBlock.height = height + "px";
    textBlock.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP; // Setzen Sie die vertikale Ausrichtung
    textBlock.textHorizontalAlignment = v_alignment;
    textBlock.top = top + "px"; // Vertikale Position relativ zum oberen Rand
    textBlock.textWrapping = true;
    advancedTexture.addControl(textBlock);
    return textBlock;
  }

  private createInstructionsPlane() {
    this.instructionsPlane = MeshBuilder.CreatePlane("instructionsPlane", { size: 1, width: 8, height: 7 }, this.scene);
    this.instructionsPlane.position = new Vector3(-0.9, +0.1, 170); // Position anpassen
    this.instructionsPlane.rotation = new Vector3(0, Math.PI / 1, 0); // Drehen um 180°

    const texture = AdvancedDynamicTexture.CreateForMesh(this.instructionsPlane);
    texture.background = "#FEDF8AE0"; // Hintergrundfarbe

    const manualComplete = new Image();
    manualComplete.source = "gui/manual.png";
    manualComplete.scaleX = 1;
    manualComplete.scaleY = 1;
    manualComplete.left = "0px"; // Position anpassen
    manualComplete.top = "0px";

    texture.addControl(manualComplete);

    const buttonClose = new Image();
    buttonClose.source = "gui/button_close.svg";
    buttonClose.scaleX = 0.05;
    buttonClose.scaleY = 0.05;
    buttonClose.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    buttonClose.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    buttonClose.left = "481px"; // Position anpassen
    buttonClose.top = "-480px";
    buttonClose.onPointerUpObservable.add(() => {
      if (this.instructionsPlane) this.instructionsPlane.isVisible = false;
    });
    texture.addControl(buttonClose);
  }

  private toggleFullscreen() {
    if (!document.fullscreenElement) {
      if (this.canvas.requestFullscreen) {
        this.canvas.requestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  private toggleAllControlsVisibility(isVisible: boolean) {
    this.controlElements.forEach((control) => {
      control.isVisible = isVisible;
      if (control.name === "logo" || control.name === "logotext") control.isVisible = true;
    });

    this.controlElementsChildren.forEach((control) => {
      control.isVisible = isVisible;
    });

    // Ein-/Ausblenden des Sliders
    if (this.slider) {
      this.slider.isVisible = isVisible;
    }
    this.link.isVisible = isVisible;
  }

  private downloadCanvasAsPNG() {
    this.toggleAllControlsVisibility(false);

    if (!this.basicScene.isFirefox()) {
      this.activateSSAO();
    }

    setTimeout(() => {
      const dataURL = this.canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = dataURL;
      link.download = "Meine Cube-Idee - www.garden-cube.eu.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      this.toggleAllControlsVisibility(true);
      if (!this.basicScene.isFirefox()) {
        this.deactivateSSAO();
        this.engine.setHardwareScalingLevel(1);
      }
    }, 2000); // Warten Sie, bis die SSAO-Verarbeitung abgeschlossen ist
  }

  private shareOnTwitter() {
    const text = "Schaut euch meinen Garden Cube an!";
    const url = "https://www.mein-garden-cube.de"; // URL zu Ihrer App oder einem Bild
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(url)}`;
    window.open(twitterUrl, "_blank");
  }

  // Funktion zum Teilen auf Facebook (nur Link, kein Bild)
  private shareOnFacebook() {
    const url = "https://www.mein-garden-cube.de"; // URL zu Ihrer App
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
    window.open(facebookUrl, "_blank");
  }

  private createImageUploadInput() {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.style.display = "none"; // Versteckt das Input-Element
    input.addEventListener("change", (event) => this.handleFileUpload(event));
    document.body.appendChild(input); // Fügt das Element dem DOM hinzu
    return input;
  }

  private handleFileUpload(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const result = e.target ? e.target.result : null;
        if (result) {
          this.resizeImageTo1920x1080(result.toString(), (resizedDataUrl) => {
            this.updateImageWallTexture(resizedDataUrl);
          });
        }
      };
      reader.readAsDataURL(file);
    }
  }

  private updateImageWallTexture(dataUrl: string) {
    // Annahme: Sie haben eine Methode in ImageWall, um die Textur zu aktualisieren
    this.imageWall.updateTexture(dataUrl);
  }

  private addSliderCubes() {
    // Slider Container
    this.cubeIcons.push(new Image("icon3", "gui/icon3.webp"));
    this.cubeIcons.push(new Image("icon2", "gui/icon2.webp"));
    this.cubeIcons.push(new Image("icon1", "gui/icon1.webp"));
    this.cubeIcons.push(new Image("icon0", "gui/icon0.webp"));
    //this.cubeIcons.push(new Image("icon4", "gui/icon4.webp"));
    this.cubeIcons.push(new Image("icon5", "gui/icon5.webp"));

    this.cubeIcons.push(new Image("icon6", "gui/icon6.webp"));
    this.cubeIcons.push(new Image("icon7", "gui/icon7.webp"));
    // this.cubeIcons.push(new Image("icon8", "gui/icon8.webp"));
    // this.cubeIcons.push(new Image("icon9", "gui/icon9.webp"));
    // this.cubeIcons.push(new Image("icon10", "gui/icon10.webp"));
    // this.cubeIcons.push(new Image("icon11", "gui/icon11.webp"));
    this.slider.width = "196px";
    this.slider.height = "680px";

    this.slider.alpha = 1;
    this.slider.background = "#FFFFFFA0";
    this.slider.isVertical = true;
    this.slider.verticalAlignment = Control.VERTICAL_ALIGNMENT_TOP;
    this.slider.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;
    this.slider.top = "36px";
    this.slider.left = "-36px";

    this.slider.shadowColor = this.shadowColor;
    this.slider.shadowBlur = this.shadowBlur;
    this.slider.shadowOffsetX = this.shadowOffsetX;
    this.slider.shadowOffsetY = this.shadowOffsetY;
    // Pfeil nach oben
    const upArrow = Button.CreateSimpleButton("upArrow", "");
    upArrow.height = "40px";
    upArrow.onPointerUpObservable.add(() => this.scrollIcons("up"));

    // Hinzufügen eines Icons oder Texts für den Pfeil
    const iconUpArrow = new Image("iconUpArrow", "gui/up_arrow.svg");
    iconUpArrow.width = "200px";
    iconUpArrow.height = "44px";
    upArrow.addControl(iconUpArrow);
    this.slider.addControl(upArrow);

    // Icons hinzufügen
    this.cubeIcons.forEach((icon) => {
      icon.width = "150px";
      icon.height = "150px";
      icon.paddingTop = "10px";
      icon.paddingBottom = "10px";
      icon.onPointerUpObservable.add(() => {
        if (icon.name) {
          this.iconClicked(icon.name);
        }
      });
      this.slider.addControl(icon);
    });

    // Pfeil nach unten
    const downArrow = Button.CreateSimpleButton("downArrow", "");
    downArrow.height = "40px";
    downArrow.onPointerUpObservable.add(() => this.scrollIcons("down"));
    // Hinzufügen eines Icons oder Texts für den Pfeil
    const iconDownArrow = new Image("iconDownArrow", "gui/down_arrow.svg");
    iconDownArrow.width = "200px";
    iconDownArrow.height = "44px";
    downArrow.addControl(iconDownArrow);
    this.slider.addControl(downArrow);
    this.guiTexture.addControl(this.slider);
    this.updateIconVisibility();
  }

  private iconClicked(iconName: string) {
    this.lastCubeType = this.currentCubeType;
    if (iconName === "icon0") {
      this.currentCubeType = 0;
    } else if (iconName === "icon1") {
      this.currentCubeType = 1;
    } else if (iconName === "icon2") {
      this.currentCubeType = 2;
    } else if (iconName === "icon3") {
      this.currentCubeType = 3;
    } else if (iconName === "icon4") {
      this.currentCubeType = 4;
    } else if (iconName === "icon5") {
      this.currentCubeType = 5;
    } else if (iconName === "icon6") {
      this.currentCubeType = 6;
    } else if (iconName === "icon7") {
      this.currentCubeType = 7;
    } else if (iconName === "icon8") {
      this.currentCubeType = 8;
    } else if (iconName === "icon9") {
      this.currentCubeType = 9;
    } else if (iconName === "icon10") {
      this.currentCubeType = 10;
    } else if (iconName === "icon11") {
      this.currentCubeType = 11;
    }
    this.basicScene.cube.getNewCubeAndHideLast(this.currentCubeType, this.lastCubeType);
  }

  private scrollIcons(direction: string) {
    if (direction === "up" && this.currentScrollIndex > 0) {
      this.currentScrollIndex--;
    } else if (direction === "down" && this.currentScrollIndex < this.cubeIcons.length - this.maxVisibleIcons) {
      this.currentScrollIndex++;
    }
    this.updateIconVisibility();
  }

  private updateIconVisibility() {
    for (let i = 0; i < this.cubeIcons.length; i++) {
      this.cubeIcons[i].isVisible = i >= this.currentScrollIndex && i < this.currentScrollIndex + this.maxVisibleIcons;
    }
  }

  private resizeImageTo1920x1080(dataUrl: string, callback: (newDataUrl: string) => void) {
    const targetWidth = 1920;
    const targetHeight = 1080;
    const img = document.createElement("img");

    img.onload = () => {
      const canvas = document.createElement("canvas");
      canvas.width = targetWidth;
      canvas.height = targetHeight;

      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      // Füllen Sie den Hintergrund mit Schwarz
      ctx.fillStyle = "black";
      ctx.fillRect(0, 0, targetWidth, targetHeight);

      // Berechnung des Seitenverhältnisses und Skalierung
      const imgAspectRatio = img.width / img.height;
      let newWidth, newHeight;

      if (imgAspectRatio < targetWidth / targetHeight) {
        // Bild ist breiter als das Zielverhältnis
        newWidth = targetHeight * imgAspectRatio;
        newHeight = targetHeight;
      } else {
        // Bild ist höher als das Zielverhältnis
        newWidth = targetWidth;
        newHeight = targetWidth / imgAspectRatio;
      }

      // Zentrieren des Bildes auf dem Canvas
      const xOffset = (targetWidth - newWidth) / 2;
      const yOffset = (targetHeight - newHeight) / 2;

      ctx.drawImage(img, xOffset, yOffset, newWidth, newHeight);
      callback(canvas.toDataURL());
    };

    img.src = dataUrl;
  }

  private activateSSAO() {
    this.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline("ssao", this.camera);
  }

  private deactivateSSAO() {
    this.scene.postProcessRenderPipelineManager.detachCamerasFromRenderPipeline("ssao", this.camera);
  }

  private initializeSSAO() {
    this.ssao = new SSAO2RenderingPipeline("ssao", this.scene, {
      ssaoRatio: 2.5, // Verhältnis der SSAO-Post-Verarbeitung
      blurRatio: 2.5, // Verhältnis der Kombination von SSAO und Szene
    });
    this.ssao.radius = 0.5;
    this.ssao.totalStrength = 1.5;
    this.ssao.expensiveBlur = true;
    this.ssao.samples = 64;
    this.ssao.maxZ = 50;

    // SSAO nicht sofort aktivieren
  }

  public destroy() {
    this.guiTexture.dispose();
  }

  private onFullscreenChange() {
    if (document.fullscreenElement) {
      console.log("Vollbildmodus aktiviert");
    } else {
      console.log("Vollbildmodus deaktiviert");
    }
  }

  private addCubeControls() {
    const controlsContainer = new Rectangle();
    controlsContainer.width = "400px";
    controlsContainer.height = "400px";
    controlsContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    controlsContainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    controlsContainer.left = "34px";
    controlsContainer.top = "-100px";
    controlsContainer.background = "#FFFFFF30";
    controlsContainer.cornerRadius = 10;
    controlsContainer.thickness = 1;
    controlsContainer.color = "#000000";
    controlsContainer.shadowColor = this.shadowColor;
    controlsContainer.shadowBlur = this.shadowBlur;
    controlsContainer.shadowOffsetX = this.shadowOffsetX;
    controlsContainer.shadowOffsetY = this.shadowOffsetY;
    this.guiTexture.addControl(controlsContainer);

    const mainGrid = new Grid();
    mainGrid.width = "100%";
    mainGrid.height = "100%";
    mainGrid.addColumnDefinition(400 / 3, true);
    mainGrid.addColumnDefinition(400 / 3, true);
    mainGrid.addColumnDefinition(400 / 3, true);
    mainGrid.addRowDefinition(400 / 3, true);
    mainGrid.addRowDefinition(400 / 3, true);
    mainGrid.addRowDefinition(400 / 3, true);
    controlsContainer.addControl(mainGrid);

    // Movement Buttons
    this.createControlButton(mainGrid, "upButton", "gui/arrow.svg", 0, 1, "move", "up");
    this.createControlButton(mainGrid, "downButton", "gui/arrow.svg", 2, 1, "move", "down", Math.PI); // Rotate down button
    this.createControlButton(mainGrid, "leftButton", "gui/arrow.svg", 1, 0, "move", "left", -Math.PI / 2); // Rotate left button
    this.createControlButton(mainGrid, "rightButton", "gui/arrow.svg", 1, 2, "move", "right", Math.PI / 2); // Rotate right button

    //this.createControlButton(mainGrid, "scaleUpButton", "gui/scale_up.svg", 0, 2, "scale", "up");
    //this.createControlButton(mainGrid, "scaleDownButton", "gui/scale_down.svg", 2, 0, "scale", "down");
    // Note: Removed scaling buttons from the main grid as per your requirement

    // Rotation Controls
    const rotationContainer = new Rectangle();
    rotationContainer.width = "240px";
    rotationContainer.height = "400px";
    rotationContainer.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_LEFT;
    rotationContainer.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    rotationContainer.left = "470px";
    rotationContainer.top = "-100px";
    rotationContainer.background = "#FFFFFF30";
    rotationContainer.cornerRadius = 10;
    rotationContainer.thickness = 1;
    rotationContainer.color = "#000000";
    rotationContainer.shadowColor = this.shadowColor;
    rotationContainer.shadowBlur = this.shadowBlur;
    rotationContainer.shadowOffsetX = this.shadowOffsetX;
    rotationContainer.shadowOffsetY = this.shadowOffsetY;
    this.guiTexture.addControl(rotationContainer);

    const rotationGrid = new Grid();
    rotationGrid.width = "100%";
    rotationGrid.height = "100%";
    rotationGrid.addColumnDefinition(120, true);
    rotationGrid.addColumnDefinition(120, true);
    rotationGrid.addRowDefinition(400 / 3, true);
    rotationGrid.addRowDefinition(400 / 3, true);
    rotationGrid.addRowDefinition(400 / 3, true);
    rotationContainer.addControl(rotationGrid);

    // Rotation Buttons mirrored for opposite direction
    this.createControlButton(rotationGrid, "rotateXPosButton", "gui/360.svg", 1, 0, "rotate", "y-", 0);
    this.createControlButton(rotationGrid, "rotateXNegButton", "gui/360mirror.svg", 2, 0, "rotate", "y+", 0);

    this.createControlButton(rotationGrid, "rotateYPosButton", "gui/360.svg", 1, 1, "rotate", "x-", Math.PI / 2);
    this.createControlButton(rotationGrid, "rotateYNegButton", "gui/360mirror.svg", 2, 1, "rotate", "x+", -Math.PI / 2);
  }

  private createControlButton(
    grid: Grid,
    name: string,
    iconPath: string,
    row: number,
    col: number,
    actionType: "move" | "rotate" | "scale",
    direction: string,
    rotation = 0,
    flipX = false,
    flipY = false
  ) {
    const button = Button.CreateImageOnlyButton(name, "");
    button.width = "120px";
    button.height = "120px";
    button.thickness = 0;

    const iconImage = new Image(name + "_icon", iconPath);
    iconImage.width = "100%";
    iconImage.height = "100%";

    // Rotation anwenden
    iconImage.rotation = rotation;

    // Spiegelung anwenden
    if (flipX) {
      iconImage.scaleX = -1;
    }
    if (flipY) {
      iconImage.scaleY = -1;
    }

    button.addControl(iconImage);

    button.onPointerDownObservable.add(() => {
      this.startAction(actionType, direction);
    });

    button.onPointerUpObservable.add(() => {
      this.stopAction();
    });

    grid.addControl(button, row, col);
  }

  private startAction(actionType: "move" | "rotate" | "scale", direction: string) {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }

    this.actionStartTime = Date.now();

    this.interval = window.setInterval(() => {
      const elapsedTime = (Date.now() - (this.actionStartTime || Date.now())) / 1000; // Zeit in Sekunden
      const speedMultiplier = Math.min(this.baseSpeedMultiplier + elapsedTime * this.speedScalingFactor, this.maxSpeedMultiplier);

      if (actionType === "move") {
        this.moveCube(direction, speedMultiplier);
      } else if (actionType === "rotate") {
        this.rotateCube(direction, speedMultiplier);
      } else if (actionType === "scale") {
        this.scaleCube(direction, speedMultiplier);
      }
    }, 50); // Intervall von 50 ms für flüssigere Steuerung
  }

  private stopAction() {
    if (this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.actionStartTime = null;
  }

  private moveCube(direction: string, speedMultiplier: number) {
    const cube = this.basicScene.cube.activeCubeMesh;
    const baseMoveSensitivity = 0.005; // Basisbewegungsgeschwindigkeit

    const moveSensitivity = baseMoveSensitivity * speedMultiplier;

    const localXAxis = new Vector3(1, 0, 0);
    const localYAxis = new Vector3(0, 1, 0);

    switch (direction) {
      case "up":
        cube.position.addInPlace(localYAxis.scale(moveSensitivity));
        break;
      case "down":
        cube.position.addInPlace(localYAxis.scale(-moveSensitivity));
        break;
      case "left":
        cube.position.addInPlace(localXAxis.scale(moveSensitivity));
        break;
      case "right":
        cube.position.addInPlace(localXAxis.scale(-moveSensitivity));
        break;
    }
  }

  private scaleCube(direction: string, speedMultiplier: number) {
    const cube = this.basicScene.cube.activeCubeMesh;
    const baseScaleAmount = 0.002; // Basis-Skalierungsmenge

    const scaleAmount = baseScaleAmount * speedMultiplier;

    switch (direction) {
      case "up":
        cube.scaling.addInPlace(new Vector3(scaleAmount, scaleAmount, scaleAmount));
        break;
      case "down":
        cube.scaling.subtractInPlace(new Vector3(scaleAmount, scaleAmount, scaleAmount));
        break;
    }
  }

  private rotateCube(direction: string, speedMultiplier: number) {
    const cube = this.basicScene.cube.activeCubeMesh;
    const baseRotateAmount = Math.PI / 359; // Basisrotationsmenge (1 Grad)

    const rotateAmount = baseRotateAmount * speedMultiplier;

    let axis: "x" | "y";
    let sign: number;

    if (direction.endsWith("+")) {
      axis = direction.charAt(0) as "x" | "y";
      sign = 1;
    } else if (direction.endsWith("-")) {
      axis = direction.charAt(0) as "x" | "y";
      sign = -1;
    } else {
      axis = direction as "x" | "y";
      sign = 1;
    }

    switch (axis) {
      case "x":
        cube.rotation.x += rotateAmount * sign;
        break;
      case "y":
        cube.rotation.y += rotateAmount * sign;
        break;
    }
  }

  private addScaleSlider() {
    // Erstellen des Sliders
    this.scaleSlider = new Slider();

    // Konfiguration des Sliders

    this.scaleSlider.minimum = 1; // Minimale Skalierung
    this.scaleSlider.maximum = 8; // Maximale Skalierung
    this.scaleSlider.value = 1.0; // Standardwert (keine Skalierung)
    this.scaleSlider.height = "240px"; // Höhe des Sliders
    this.scaleSlider.width = "50px"; // Breite des Sliders
    this.scaleSlider.color = "white"; // Farbe des Slider-Fortschritts
    this.scaleSlider.background = "gray"; // Hintergrundfarbe des Sliders
    this.scaleSlider.isThumbCircle = true; // Runde Daumen
    this.scaleSlider.isVertical = true;
    this.scaleSlider.barOffset = 100; // Position des Fortschrittsbalkens
    this.scaleSlider.left = "-380px"; // Positionierung des Sliders in der Mitte der GUI
    this.scaleSlider.top = "-80px";
    // Positionierung des Sliders in der Mitte der GUI
    this.scaleSlider.verticalAlignment = Control.VERTICAL_ALIGNMENT_BOTTOM;
    this.scaleSlider.horizontalAlignment = Control.HORIZONTAL_ALIGNMENT_RIGHT;

    this.scaleSlider.thumbWidth = "40px";
    this.scaleSlider.isThumbClamped = true; // Daumen wird nicht über den Slider hinausgezogen
    // Event-Listener für Wertänderungen
    this.scaleSlider.onValueChangedObservable.add((value) => {
      this.scaleCubeBySlider(value);
    });

    // Hinzufügen des Sliders zur GUI
    this.guiTexture.addControl(this.scaleSlider);

    // Setzen des Sliders auf den aktuellen Skalierungswert des Würfels
    const cube = this.basicScene.cube.activeCubeMesh;
    if (cube) {
      const initialScale = cube.scaling.x; // Annahme: gleichmäßige Skalierung
      this.scaleSlider.value = initialScale;
    }
  }

  private scaleCubeBySlider(value: number) {
    const cube = this.basicScene.cube.activeCubeMesh;
    if (cube) {
      cube.scaling = new Vector3(value, value, value);
    }
  }
}
