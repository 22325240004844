import { Vector3, Mesh, Scene, SceneLoader, ShadowGenerator, PBRMaterial, Texture, Color3, ArcRotateCamera } from "@babylonjs/core";
import { CustomGizmoManager } from "./CustomGizmoManager"; // Angepasster Import
import { ProgressBar } from "./ProgressBar";
import { SunLight } from "./SunLight";

export class Cube {
  public cube: Mesh;
  public allFileNames: string[];
  public allCubeMeshes: Mesh;
  public GizmoManager: CustomGizmoManager;
  public isLoadingComplete = false;
  private progressbar: ProgressBar;
  public MeshAndSubmeshes: { [filename: string]: Mesh[] };
  public cubes: Mesh[];
  public activeCubeMesh: Mesh;
  public actualCubeType: number;
  private shadowGenerator: ShadowGenerator;
  public materialRahmen: PBRMaterial;
  public materialInlaySchach: PBRMaterial;
  constructor(private scene: Scene, progressbar: ProgressBar, light: SunLight) {
    this.cube = new Mesh("cube", scene);
    this.allCubeMeshes = new Mesh("cube", scene);
    this.allFileNames = [];
    this.progressbar = progressbar;
    this.MeshAndSubmeshes = {};
    this.cubes = [];
    this.activeCubeMesh = this.cubes[0];
    this.actualCubeType = 0;
    this.GizmoManager = CustomGizmoManager;
    this.shadowGenerator = new ShadowGenerator(1024, light.sunlight);
    this.scene = scene;
    this.materialRahmen = new PBRMaterial("PBRMaterial", this.scene);
    this.materialInlaySchach = new PBRMaterial("PBRMaterial", this.scene);
  }

  public async loadAllCubeMeshes(): Promise<void> {
    this.allFileNames.push("boundingbox.obj", "liege.glb", "tisch.obj", "schaukeln.obj", "fuesse.obj", "rahmen.obj", "rahmen_unten.obj", "dach.obj", "1schatten_rahmen_unten.obj");
    this.allFileNames.push(
      "inlay_holz.obj",
      "inlay_grill.glb",
      "inlay_schach.obj",
      "inlay_scrabble.obj",
      "inlay_muehle.obj",
      "inlay_halma.obj",
      "inlay_leiterspiel.obj",
      "inlay_mensch_aergere_dich_nicht.glb",
      "inlay_backgamon.obj"
    );
    const cacheBuster = Date.now();
    for (let index = 0; index < this.allFileNames.length; index++) {
      const filename = this.allFileNames[index];

      try {
        await SceneLoader.ImportMeshAsync("", "cube/", filename + "?v=" + cacheBuster, this.scene).then((result) => {
          this.MeshAndSubmeshes[filename] = []; // Initialisieren des Arrays für das Mesh

          result.meshes.forEach((abstractMesh) => {
            if (abstractMesh instanceof Mesh) {
              abstractMesh.isVisible = false; // Setze die Sichtbarkeit des Meshes
              this.MeshAndSubmeshes[filename].push(abstractMesh); // Fügen Sie das Mesh dem Array hinzu
            }
          });

          // Aktualisieren der Progressbar
          const progress = (index + 1) / this.allFileNames.length;
          this.progressbar.update(progress);
        });
      } catch (error) {
        console.error(`Fehler beim Laden von Mesh: ${filename}`, error);
      }
    }
    this.createMaterials();
    this.cubes.push(this.CreateCube(0));
    this.cubes.push(this.CreateCube(1));
    this.cubes.push(this.CreateCube(2));
    this.cubes.push(this.CreateCube(3));
    this.cubes.push(this.CreateCube(4));
    this.cubes.push(this.CreateCube(5));
    this.cubes.push(this.CreateCube(6));
    this.cubes.push(this.CreateCube(7));
    this.cubes.push(this.CreateCube(8));
    this.cubes.push(this.CreateCube(9));
    this.cubes.push(this.CreateCube(10));
    this.cubes.push(this.CreateCube(11));

    this.activeCubeMesh = this.cubes[0];
    this.isLoadingComplete = true;

    //Initialize the cubes Transformations
    for (let i = 0; i < this.cubes.length; i++) {
      this.cubes[i].scaling = new Vector3(3.0, 3.0, 3.0);
      this.cubes[i].position = new Vector3(7.5, -1.8, 150);
      this.cubes[i].rotation = new Vector3(0.02, -0.77, -0.03);
    }
  }

  public CreateCube(cubeNumber: number): Mesh {
    console.log("GetCube called");
    const combinedMesh = new Mesh(`cubeVariant${cubeNumber}`, this.scene); //return combinedMesh;
    combinedMesh.id = cubeNumber.toString();
    const ChoosenComination: string[] = this.CombineMeshes(cubeNumber);

    for (const filename of ChoosenComination) {
      const MeshContainer = this.MeshAndSubmeshes[filename];
      if (MeshContainer) {
        for (let i = 0; i < MeshContainer.length; i++) {
          MeshContainer[i].isVisible = false; // Hide all meshes first
          const clone = MeshContainer[i].clone(`${MeshContainer[i].name}`);
          if (clone) {
            clone.isVisible = false; // Setze die Sichtbarkeit des Klon-Meshes
            clone.isPickable = false;
            clone.receiveShadows = true;
            this.shadowGenerator?.getShadowMap()?.renderList?.push(clone);
            if (clone.name == "BoundingBox") {
              clone.name = "BoundingBox";
              clone.isPickable = true;
              clone.visibility = 0.0;
            }
            if (clone.name == "rahmen" || clone.name == "rahmen_unten_Mesh" || clone.name == "fuesse_Mesh" || clone.name == "Tisch_Rahmen") {
              clone.material = this.materialRahmen;
            }
            if (clone.name == "Inlay_Schach") {
              //  clone.material = this.materialInlaySchach;
            }
            combinedMesh.addChild(clone);
          }
        }
      }
    }
    combinedMesh.isPickable = false;
    return combinedMesh;
  }

  public showCube(MeshContainer: Mesh) {
    const childMeshes = MeshContainer.getChildMeshes();
    for (let i = 0; i < childMeshes.length; i++) {
      childMeshes[i].isVisible = true;
    }
  }

  public hideCube(MeshContainer: Mesh) {
    const childMeshes = MeshContainer.getChildMeshes();
    for (let i = 0; i < childMeshes.length; i++) {
      childMeshes[i].isVisible = false;
    }
  }

  public dispose() {
    this.cube.dispose();
  }

  public getNewCubeAndHideLast(newCubeType: number, lastCubeType: number): void {
    const lastCube = this.cubes[lastCubeType];
    const newCube = this.cubes[newCubeType];
    newCube.position = lastCube.position.clone();
    newCube.rotation = lastCube.rotation.clone();
    newCube.scaling = lastCube.scaling.clone();
    this.hideCube(lastCube);
    this.showCube(newCube);
    CustomGizmoManager.attachToMesh(newCube);
    this.activeCubeMesh = newCube; // Fügen Sie diese Zeile hinzu
    if (this.scene.activeCamera instanceof ArcRotateCamera) {
      // Wenn der MouseController vorhanden ist, aktualisieren Sie das pickedObject
      (this.scene.activeCamera.inputs.attached.pointers as any).mouseController.updatePickedObject(newCube);
    }
  }

  private CombineMeshes(cubeNumber: number): string[] {
    let filenamesToCombine: string[];
    if (cubeNumber === 0) {
      filenamesToCombine = ["boundingbox.obj", "liege.glb", "fuesse.obj", "rahmen.obj", "dach.obj"];
    } else if (cubeNumber === 1) {
      filenamesToCombine = ["boundingbox.obj", "liege.glb", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 2) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_holz.obj", "schaukeln.obj", "fuesse.obj", "rahmen.obj", "dach.obj"];
    } else if (cubeNumber === 3) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_holz.obj", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 4) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_grill.glb", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 5) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_schach.obj", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 6) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_scrabble.obj", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 7) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_muehle.obj", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 8) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_halma.obj", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 9) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_leiterspiel.obj", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 10) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_mensch_aergere_dich_nicht.glb", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else if (cubeNumber === 11) {
      filenamesToCombine = ["boundingbox.obj", "tisch.obj", "inlay_backgamon.obj", "schaukeln.obj", "rahmen_unten.obj", "rahmen.obj", "dach.obj", "schatten_rahmen_unten.obj"];
    } else {
      throw new Error("Ungültige cubeNumber");
    }
    return filenamesToCombine;
  }

  createMaterials() {
    this.materialRahmen = new PBRMaterial("MaterialRahmen", this.scene); //it is db703 color
    this.materialRahmen.albedoColor = new Color3(0.858, 0.858, 0.858);
    this.materialRahmen.albedoTexture = new Texture("cube/textures/miniperl.jpg", this.scene);

    // this.materialRahmen.bumpTexture = new Texture("cube/textures/miniperl.jpg", this.scene);
    // this.materialRahmen.bumpTexture.level = 5.5;
    // this.materialRahmen.bumpTexture.invertZ = true;

    this.materialRahmen.metallic = 0.8;
    this.materialRahmen.specularIntensity = 0.7;
    this.materialRahmen.roughness = 0.25;

    this.materialInlaySchach = new PBRMaterial("MaterialInlaySchach", this.scene);
    this.materialInlaySchach.albedoTexture = new Texture("cube/textures/inlay_schach.png", this.scene);
    this.materialInlaySchach.albedoTexture.level = 4.0;
    this.materialInlaySchach.metallic = 0.0;
    this.materialInlaySchach.specularIntensity = 0.5;
    this.materialInlaySchach.roughness = 0.2;

    /*
    this.materialRahmen.environmentIntensity = 1.;
    this.materialRahmen.reflectivityColor = new Color3(0.858, 0.858, 0.858);
    this.materialRahmen.microSurface = 0.5;
    this.materialRahmen.useMicroSurfaceFromReflectivityMapAlpha = true;
    this.materialRahmen.useRoughnessFromMetallicTextureAlpha = false;
    this.materialRahmen.useRoughnessFromMetallicTextureGreen = true;
    this.materialRahmen.useMetallnessFromMetallicTextureBlue = true;
    this.materialRahmen.useAmbientOcclusionFromMetallicTextureRed = true;
    this.materialRahmen.useAmbientInGrayScale = false;
    this.materialRahmen.useSpecularOverAlpha = true;
    this.materialRahmen.useRadianceOverAlpha = true;
    this.materialRahmen.usePhysicalLightFalloff = true;
    this.materialRahmen.directIntensity = 1.0;
    this.materialRahmen.environmentIntensity = 0.1;
    this.materialRahmen.specularIntensity = 1.0;
    this.materialRahmen.cameraExposure = 0.6;
    this.materialRahmen.cameraContrast = 1.6;
    this.materialRahmen.useRadianceOcclusion = true;
    this.materialRahmen.useHorizonOcclusion = true;
    this.materialRahmen.useRadianceOcclusion = true;
    this.materialRahmen.unlit = false;
    this.materialRahmen.transparencyMode = 0;
*/
  }
}
