import { Scene, Engine, HemisphericLight, Mesh, Vector3, DefaultRenderingPipeline } from "@babylonjs/core";
import { CustomGizmoManager } from "./CustomGizmoManager";
import "@babylonjs/loaders";
import { SunLight } from "./SunLight";
import { Ground } from "./Ground";
import { HemiLight } from "./HemiLight";
import { RotatingCamera } from "./ArcRotateCamera";
import { StaticCamera } from "./StaticCamera";
import { Cube } from "./Cube";
import { MouseController } from "./MouseController";
import { TouchController } from "./TouchController";
import { SkyBox } from "./SkyBox";
import { SkyMaterial } from "@babylonjs/materials";
import { ImageWall } from "./ImageWall";
import { GUI } from "./GUI";
import { LoadingScene } from "./LoadingScene"; // New import

export class BasicScene {
  private isDragging: boolean = false;
  private canvas: HTMLCanvasElement;
  private mainScene: Scene;
  private loadingScene: LoadingScene; // Changed from Scene to LoadingScene
  private engine: Engine;
  private rotatingCamera: RotatingCamera;
  private staticCamera: StaticCamera;
  private sunlight: SunLight;
  private mouseController?: MouseController;
  private touchController?: TouchController;

  private skyBox: SkyBox;

  public cube: Cube;
  private hemilight: HemiLight;

  private gui?: GUI;

  private ground?: Ground;
  private modeChanged: boolean;

  private skybox?: Mesh;
  public isInFullScreen: boolean;
  private manualUpdate: boolean;
  private alpha: number;
  public mode!: number;
  private imageWall: ImageWall;

  private cubeIcons: Mesh[];
  public actualCubeType: number;

  public showLoadingScreen: boolean; // New property

  constructor(canvas: HTMLCanvasElement, showLoadingScreen: boolean = true) {
    this.actualCubeType = 0;
    this.modeChanged = false;
    this.alpha = 6;
    this.mode = 1;
    this.manualUpdate = false;
    this.canvas = canvas;
    this.showLoadingScreen = showLoadingScreen; // Initialize the new property

    this.cubeIcons = [];

    const canvasWidth = 1920;
    const canvasHeight = 1080;
    this.canvas.width = canvasWidth;
    this.canvas.height = canvasHeight;

    this.engine = new Engine(this.canvas, false, { preserveDrawingBuffer: true, stencil: true, disableWebGL2Support: false });
    this.mainScene = new Scene(this.engine);
    CustomGizmoManager.initialize(this.mainScene);
    this.loadingScene = new LoadingScene(this.engine); // Use the new LoadingScene class

    this.imageWall = new ImageWall(this.mainScene, this.mode);
    this.staticCamera = new StaticCamera(this.mainScene);
    this.rotatingCamera = new RotatingCamera(this.mainScene);
    this.sunlight = new SunLight(this.mainScene);
    this.skyBox = new SkyBox(this.mainScene);
    this.ground = new Ground(this.mainScene);
    this.hemilight = new HemiLight(this.mainScene);
    this.isInFullScreen = false;
    this.cube = new Cube(this.mainScene, this.loadingScene.progressBar, this.sunlight);

    this.initializeControllers();

    window.addEventListener("resize", () => this.handleWindowResize());
    window.addEventListener("orientationchange", () => this.handleWindowResize());
    this.engine.runRenderLoop(() => {
      if (this.cube.isLoadingComplete) {
        this.mainScene.render();
      } else if (this.showLoadingScreen) {
        this.loadingScene.scene.render();
      }
    });
    this.createMainScene();
  }

  public async createMainScene() {
    if (this.showLoadingScreen) {
      this.handleWindowResize();
      this.loadingScene.show();
    }

    this.disposeAll();

    this.skyBox = new SkyBox(this.mainScene);
    this.skybox = this.skyBox.create();
    (this.skybox.material as SkyMaterial).inclination = Math.cos(this.alpha);
    this.mainScene.activeCamera = this.staticCamera.create();
    await this.cube.loadAllCubeMeshes();

    const Hemi2 = new HemisphericLight("hemiLight", new Vector3(0, 1, 1));
    Hemi2.intensity = 0.5;
    this.hemilight = new HemiLight(this.mainScene);
    this.hemilight.create();
    this.hemilight.hemiLight.intensity = 1.5;

    this.sunlight = new SunLight(this.mainScene);
    this.sunlight.create();
    this.sunlight.sunlight.position = new Vector3(Math.cos(this.alpha) * 100, Math.sin(this.alpha) * 100, 0);
    this.sunlight.sunlight.intensity = 0.0;
    this.imageWall = new ImageWall(this.mainScene, this.mode);
    this.imageWall.create();
    this.gui = new GUI(this.mainScene, this.staticCamera.camera, this.canvas, this.imageWall, this, this.engine);

    this.initializeControllers();

    this.mainScene.createDefaultEnvironment();
    this.handleWindowResize();

    const defaultPipeline = new DefaultRenderingPipeline("default", false, this.mainScene, [this.staticCamera.camera]);

    defaultPipeline.fxaaEnabled = true;

    if (this.showLoadingScreen) {
      this.loadingScene.hide();
    }

    // Start rendering the main scene
    this.engine.runRenderLoop(() => {
      this.mainScene.render();
    });
  }

  private disposeAll(): void {
    console.log("disposeAll");
    if (this.touchController) {
      this.touchController.dispose();
      this.touchController = undefined;
    }
    if (this.mouseController) {
      this.mouseController.dispose();
      this.mouseController = undefined;
    }

    if (this.rotatingCamera) this.rotatingCamera.dispose();
    if (this.staticCamera) this.staticCamera.dispose();
    if (this.cube) this.cube.dispose();
    if (this.skyBox) this.skyBox.dispose();
    if (this.ground) this.ground.dispose();
    if (this.imageWall) this.imageWall.dispose();
    if (this.mainScene.activeCamera) this.mainScene.activeCamera.dispose();
    if (this.imageWall) this.imageWall.dispose();
    if (this.sunlight) this.sunlight.dispose();
    if (this.hemilight) this.hemilight.dispose();
  }

  private initializeControllers(): void {
    if (this.isTouchDevice()) {
      console.log("Touch-Gerät erkannt. Aktiviere Touch-Controller.");
      this.touchController = new TouchController(this.mainScene, this.cube.cubes);
      this.mouseController = undefined; // Deaktiviere MouseController
    } else {
      console.log("Kein Touch-Gerät erkannt. Aktiviere Maus-Controller.");
      this.mouseController = new MouseController(this.mainScene, this.cube.cubes);
      this.touchController = undefined; // Deaktiviere TouchController
      (this.mainScene as any).mouseController = this.mouseController; // Der Szene den MouseController hinzufügen
    }
  }

  private isTouchDevice(): boolean {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      // Überprüfung auf msMaxTouchPoints ohne 'any'
      ("msMaxTouchPoints" in navigator && (navigator as Navigator & { msMaxTouchPoints: number }).msMaxTouchPoints > 0) ||
      "ontouchstart" in document.documentElement
    );
  }

  public updateSunPosition(hour: number): void {
    this.manualUpdate = true;
    this.alpha = ((hour % 24) / 24) * Math.PI * 1;
    this.sunlight.updateLightDirection(this.alpha);
    this.mainScene.render();
    console.log("updateSunPosition");
  }

  public recreateGUI() {
    if (this.gui) {
      this.gui.destroy();
      this.gui = new GUI(this.mainScene, this.staticCamera.camera, this.canvas, this.imageWall, this, this.engine);
      this.gui.guiTexture.scaleTo(1920, 1080);
    }
  }

  public handleWindowResize() {
    this.recreateGUI();
    let windowed = true;
    if (document.fullscreenElement != null) {
      windowed = false;
    } else {
      windowed = true;
    }
    console.log("resized");
    const targetAspectRatio = 16 / 9;
    const viewportWidth = document.documentElement.clientWidth;
    const viewportHeight = document.documentElement.clientHeight;
    console.log("viewportWidth: " + viewportWidth);
    console.log("viewportHeight: " + viewportHeight);
    let scale;
    if (viewportWidth / viewportHeight > targetAspectRatio) {
      scale = viewportHeight / 9;
    } else {
      scale = viewportWidth / 16;
    }
    const newCanvasWidth = 16 * scale;
    const newCanvasHeight = 9 * scale;

    if (windowed) {
      if (this.isFirefox()) {
        this.engine.setHardwareScalingLevel(1);
        this.recreateGUI();
        this.canvas.width = newCanvasWidth / 1.0;
        this.canvas.height = newCanvasHeight / 1.0;
        this.canvas.style.width = newCanvasWidth / 1.0 + "px";
        this.canvas.style.height = newCanvasHeight / 1.0 + "px";
      } else {
        this.canvas.width = newCanvasWidth;
        this.canvas.height = newCanvasHeight;
        this.canvas.style.width = newCanvasWidth + "px";
        this.canvas.style.height = newCanvasHeight + "px";
      }
    } else {
      if (this.isFirefox()) {
        if (viewportWidth > 1920) {
          this.engine.setHardwareScalingLevel(1.3);
        } else {
          this.engine.setHardwareScalingLevel(1);
          this.canvas.width = viewportWidth;
          this.canvas.height = viewportHeight;
          this.canvas.style.width = viewportWidth + "px";
          this.canvas.style.height = viewportHeight + "px";
        }
      } else {
        this.canvas.width = viewportWidth;
        this.canvas.height = viewportHeight;
        this.canvas.style.width = viewportWidth + "px";
        this.canvas.style.height = viewportHeight + "px";
      }
    }
  }

  public isFirefox(): boolean {
    return navigator.userAgent.indexOf("Firefox") !== -1;
  }

  // Neue Methode zum Umschalten zwischen Touch- und Maus-Steuerung
  public toggleControlMode(): void {
    if (this.touchController) {
      console.log("Wechsel zu Maus-Steuerung");
      this.touchController.dispose();
      this.touchController = undefined;
      this.mouseController = new MouseController(this.mainScene, this.cube.cubes);
    } else if (this.mouseController) {
      console.log("Wechsel zu Touch-Steuerung");
      this.mouseController.dispose();
      this.mouseController = undefined;
      this.touchController = new TouchController(this.mainScene, this.cube.cubes);
    }
  }
}
