import { Scene, Mesh, Vector3, PointerEventTypes, Nullable, Observer, PointerInfo } from "@babylonjs/core";
import { CustomGizmoManager } from "./CustomGizmoManager";
export class MouseController {
  private scene: Scene;
  private pickedObject: Mesh;
  private moveSensitivity: number;
  public leftButtonPressed = false;
  public rightButtonPressed = false;
  public mouseIsMoving = false;
  private lastPointerX = 0;
  private lastPointerY = 0;
  private cubes: Mesh[];

  public timerEnded: boolean = false;
  private pointerDragObserver: Nullable<Observer<PointerInfo>> = null;

  constructor(scene: Scene, cubes: Mesh[]) {
    this.scene = scene;
    this.moveSensitivity = 0.01;
    this.enable();
    this.cubes = cubes;
    this.pickedObject = this.cubes[0];
  }

  private onPointerDown(eventData: PointerInfo) {
    if (eventData.event.button === 0) {
      this.leftButtonPressed = true;
      const pickResult = this.scene.pick(this.scene.pointerX, this.scene.pointerY);

      if (pickResult.pickedMesh) {
        if (pickResult.hit) {
          console.log(pickResult.pickedMesh.name);
          if (pickResult.pickedMesh.name.includes("cubeVariant") || pickResult.pickedMesh.name.includes("BoundingBox")) {
            console.log(pickResult.pickedMesh.name);
            this.pickedObject = pickResult.pickedMesh.parent as Mesh;
            CustomGizmoManager.showGizmo();
          } else {
            CustomGizmoManager.hideGizmo();
          }
          if (pickResult.pickedMesh.name.includes("instruc")) {
            CustomGizmoManager.hideGizmo();
          }
        }

        this.lastPointerX = this.scene.pointerX;
        this.lastPointerY = this.scene.pointerY;
      }
    }
    if (eventData.event.button === 2) {
      this.rightButtonPressed = true;
    }
    this.resetHideGizmoTimer();
  }

  private onPointerUp(eventData: PointerInfo) {
    if (eventData.event.button === 0) {
      this.leftButtonPressed = false;
    } else if (eventData.event.button === 2) {
      this.rightButtonPressed = false;
    }
  }

  private onPointerMove() {
    this.mouseIsMoving = true;
    const diffX = this.scene.pointerX - this.lastPointerX;
    const diffY = this.scene.pointerY - this.lastPointerY;
    this.lastPointerX = this.scene.pointerX;
    this.lastPointerY = this.scene.pointerY;

    if (this.leftButtonPressed) {
      if (this.pickedObject) {
        const id = parseInt(this.pickedObject.id as string);
        console.log(id);
        const localXAxis = new Vector3(1, 0, 0);
        const localYAxis = new Vector3(0, 1, 0);
        this.cubes[id].position.addInPlace(localXAxis.scale(-diffX * this.moveSensitivity));
        this.cubes[id].position.addInPlace(localYAxis.scale(-diffY * this.moveSensitivity));
      }
    }

    if (this.rightButtonPressed && this.pickedObject) {
      console.log(this.pickedObject.name);
      this.pickedObject.rotation.y -= diffX * 0.005;
      this.pickedObject.rotation.x += diffY * 0.005;
    }
    this.resetHideGizmoTimer();
  }

  private onPointerWheel(eventData: PointerInfo) {
    if (eventData.event instanceof WheelEvent && this.pickedObject) {
      const wheelDeltaY = eventData.event.deltaY;
      const scaleMultiplier = eventData.event.shiftKey ? -0.001 : -0.0001;
      const scaleAmount = wheelDeltaY * scaleMultiplier;
      this.pickedObject.scaling.scaleInPlace(1 + scaleAmount);
      for (let index = 0; index < this.cubes.length; index++) {
        this.cubes[index].scaling = this.pickedObject.scaling;
      }
    }
  }

  private attachControl() {
    this.scene.onPointerObservable.add((eventData) => {
      switch (eventData.type) {
        case PointerEventTypes.POINTERDOWN:
          this.onPointerDown(eventData);
          break;
        case PointerEventTypes.POINTERUP:
          this.onPointerUp(eventData);
          break;
        case PointerEventTypes.POINTERMOVE:
          this.onPointerMove();
          break;
        case PointerEventTypes.POINTERWHEEL:
          this.onPointerWheel(eventData);
          break;
      }
    });
  }

  private detachControl() {
    if (this.pointerDragObserver) {
      this.scene.onPointerObservable.remove(this.pointerDragObserver);
      this.pointerDragObserver = null;
    }
  }

  public enable() {
    this.attachControl();
  }

  public disable() {
    this.detachControl();
  }

  public dispose() {
    this.disable();
  }
  private resetHideGizmoTimer() {
    CustomGizmoManager.resetHideGizmoTimer(this); // Setzt den Timer zum Ausblenden des Gizmos zurück
  }

  public updatePickedObject(newPickedObject: Mesh) {
    this.pickedObject = newPickedObject;
  }
}
