import { Scene, Engine, UniversalCamera, Vector3, HemisphericLight } from "@babylonjs/core";
import { ProgressBar } from "./ProgressBar";

export class LoadingScene {
  public scene: Scene;
  private engine: Engine;
  public progressBar: ProgressBar;

  constructor(engine: Engine) {
    this.engine = engine;
    this.scene = new Scene(this.engine);

    // Resize the engine when the window is resized
    window.addEventListener("resize", () => {
      this.engine.resize();
    });

    // Create and set up the camera
    const camera = new UniversalCamera("camera", new Vector3(0, 0, 180), this.scene);
    camera.setTarget(Vector3.Zero());
    this.scene.activeCamera = camera;

    // Create and configure the light
    const light = new HemisphericLight("hemiLight", new Vector3(0, 1, 0), this.scene);
    light.intensity = 1.5;

    // Create and configure the progress bar
    this.progressBar = new ProgressBar(this.scene);
    this.progressBar.addRotatingLogo();
  }

  public show(): void {
    // Run the render loop
    this.engine.runRenderLoop(() => {
      this.scene.render();
    });
  }

  public hide(): void {
    // Stop the render loop
    this.engine.stopRenderLoop();
  }
}
