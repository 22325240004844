import { AbstractMesh, Scene, GizmoManager, Vector3, IScaleGizmo } from "@babylonjs/core";

import { MouseController } from "./MouseController";
export class CustomGizmoManager {
  private static gizmoManager: GizmoManager;
  private static hideGizmoTimeout: number | null = null;
  private static currentMesh: AbstractMesh | null = null;
  public static lastRotation: Vector3 | null = null;
  public static lastScaling: Vector3 | null = null;

  public static initialize(scene: Scene) {
    console.log("CustomGizmoManager initialized");
    this.gizmoManager = new GizmoManager(scene);
    this.showGizmo();

    if (this.gizmoManager.gizmos.scaleGizmo) {
      const scaleGizmo: IScaleGizmo = this.gizmoManager.gizmos.scaleGizmo;
      scaleGizmo.xGizmo.isEnabled = false;
      scaleGizmo.yGizmo.isEnabled = false;
      scaleGizmo.zGizmo.isEnabled = false;
      scaleGizmo.uniformScaleGizmo.isEnabled = true;
    }
    this.attachToMesh(null);
  }

  public static attachToMesh(mesh: AbstractMesh | null) {
    if (!this.gizmoManager) {
      console.error("GizmoManager not initialized");
      return;
    }
    this.gizmoManager.attachToMesh(mesh);
    console.log("Gizmo attached to mesh " + mesh?.name);
    this.showGizmo();
  }

  public static resetHideGizmoTimer(mouseController: MouseController) {
    if (this.hideGizmoTimeout) {
      clearTimeout(this.hideGizmoTimeout);
    }

    this.hideGizmoTimeout = setTimeout(() => {
      if (!mouseController.leftButtonPressed) {
        this.hideGizmo();
      }
    }, 2000); // 2 Sekunden Wartezeit
  }

  public static showGizmo() {
    if (!this.gizmoManager) {
      console.error("GizmoManager not initialized");
      return;
    }

    console.log("Gizmo is attached");
    //  this.gizmoManager.positionGizmoEnabled = true;
    this.gizmoManager.rotationGizmoEnabled = true;
    this.gizmoManager.scaleGizmoEnabled = true;
  }

  public static hideGizmo() {
    if (!this.gizmoManager) {
      console.error("GizmoManager not initialized");
      return;
    }
    //this.gizmoManager.positionGizmoEnabled = false;
    this.gizmoManager.rotationGizmoEnabled = false;
    this.gizmoManager.scaleGizmoEnabled = false;
    // Trennen des Gizmos von der aktuellen Mesh-Verbindung

    //  this.attachToMesh(null);

    // Zurücksetzen des aktuellen Meshes
    this.currentMesh = null;
  }

  public static isGizmoAttached(): boolean {
    return this.currentMesh != null;
  }
}
