import { Scene, Mesh, Vector3, PointerEventTypes, IMouseEvent } from "@babylonjs/core";
import { CustomGizmoManager } from "./CustomGizmoManager";

export class TouchController {
  private scene: Scene;
  private pickedObject: Mesh | null = null;
  private lastPointerX: number = 0;
  private lastPointerY: number = 0;
  private moveSensitivity: number = 0.01;
  private rotateSensitivity: number = 0.005;
  private scaleSensitivity: number = 0.01;
  private cubes: Mesh[];
  private multiTouchStartDistance: number | null = null;
  private multiTouchStartAngle: number | null = null;
  private isMultiTouch: boolean = false;

  constructor(scene: Scene, cubes: Mesh[]) {
    this.scene = scene;
    this.cubes = cubes;
    this.enable();
  }

  private onPointerDown(event: IMouseEvent) {
    const pickResult = this.scene.pick(event.x, event.y);
    if (pickResult.hit && pickResult.pickedMesh) {
      this.pickedObject = pickResult.pickedMesh.parent as Mesh;
      CustomGizmoManager.showGizmo();
      CustomGizmoManager.attachToMesh(this.pickedObject);
    } else {
      this.pickedObject = null;
      CustomGizmoManager.hideGizmo();
    }
    this.lastPointerX = event.x;
    this.lastPointerY = event.y;

    this.multiTouchStartDistance = null;
    this.multiTouchStartAngle = null;
    this.isMultiTouch = false;
  }

  private onPointerMove(event: IMouseEvent) {
    if (this.isTouch(event)) {
      this.handleTouchMove(event);
    } else {
      this.handleMouseMove(event);
    }
  }

  private isTouch(event: IMouseEvent): boolean {
    // Überprüfe, ob es sich um ein Touch-Event handelt
    if ("touches" in event) {
      return true;
    }

    interface FirefoxMouseEvent extends IMouseEvent {
      mozInputSource?: number;
    }
    // Überprüfe auf Firefox-spezifische Touch-Event-Eigenschaften
    const firefoxEvent = event as FirefoxMouseEvent;
    if (firefoxEvent.mozInputSource !== undefined) {
      return firefoxEvent.mozInputSource === 5; // 5 steht für MOZILLA_DOM_KEYCODE_TYPE_TOUCH
    }

    // Für andere Browser können wir die Abwesenheit von Maus-spezifischen Eigenschaften prüfen
    // und das Vorhandensein von Touch-spezifischen Eigenschaften
    return event.movementX === undefined && event.movementY === undefined && ("clientX" in event || "clientY" in event);
  }

  private handleMouseMove(event: IMouseEvent) {
    if (this.pickedObject) {
      const diffX = event.x - this.lastPointerX;
      const diffY = event.y - this.lastPointerY;

      const localXAxis = new Vector3(1, 0, 0);
      const localYAxis = new Vector3(0, 1, 0);
      this.pickedObject.position.addInPlace(localXAxis.scale(-diffX * this.moveSensitivity));
      this.pickedObject.position.addInPlace(localYAxis.scale(-diffY * this.moveSensitivity));

      this.lastPointerX = event.x;
      this.lastPointerY = event.y;
    }
  }

  private handleTouchMove(event: IMouseEvent) {
    if ("touches" in event) {
      // Touch-Event-Logik
      const touchEvent = event as unknown as TouchEvent;
      if (touchEvent.touches.length === 1 && this.pickedObject) {
        // Single touch - move
        const touch = touchEvent.touches[0];
        const diffX = touch.clientX - this.lastPointerX;
        const diffY = touch.clientY - this.lastPointerY;

        const localXAxis = new Vector3(1, 0, 0);
        const localYAxis = new Vector3(0, 1, 0);
        this.pickedObject.position.addInPlace(localXAxis.scale(-diffX * this.moveSensitivity));
        this.pickedObject.position.addInPlace(localYAxis.scale(-diffY * this.moveSensitivity));

        this.lastPointerX = touch.clientX;
        this.lastPointerY = touch.clientY;
      } else if (touchEvent.touches.length === 2 && this.pickedObject) {
        // Multi-touch - rotate and scale
        const touch1 = touchEvent.touches[0];
        const touch2 = touchEvent.touches[1];

        const currentDistance = Math.hypot(touch1.clientX - touch2.clientX, touch1.clientY - touch2.clientY);
        const currentAngle = Math.atan2(touch1.clientY - touch2.clientY, touch1.clientX - touch2.clientX);

        if (this.multiTouchStartDistance === null) {
          this.multiTouchStartDistance = currentDistance;
          this.multiTouchStartAngle = currentAngle;
        } else {
          // Scale
          const scaleFactor = 1 + (currentDistance - this.multiTouchStartDistance) * this.scaleSensitivity;
          this.pickedObject.scaling.scaleInPlace(scaleFactor);

          // Rotate
          const rotationAngle = currentAngle - this.multiTouchStartAngle!;
          this.pickedObject.rotate(Vector3.Up(), rotationAngle * this.rotateSensitivity);

          this.multiTouchStartDistance = currentDistance;
          this.multiTouchStartAngle = currentAngle;
        }
      }
    } else {
      // Behandle es als normales Pointer-Event
      this.handleMouseMove(event);
    }
  }

  private onPointerUp() {
    this.pickedObject = null;
    CustomGizmoManager.hideGizmo();
    this.multiTouchStartDistance = null;
    this.multiTouchStartAngle = null;
    this.isMultiTouch = false;
  }

  public enable() {
    this.scene.onPointerObservable.add((pointerInfo) => {
      switch (pointerInfo.type) {
        case PointerEventTypes.POINTERDOWN:
          this.onPointerDown(pointerInfo.event);
          break;
        case PointerEventTypes.POINTERMOVE:
          this.onPointerMove(pointerInfo.event);
          break;
        case PointerEventTypes.POINTERUP:
          this.onPointerUp();
          break;
      }
    });
  }

  public disable() {
    // Hier könnten Sie die Pointer-Events deaktivieren, wenn nötig
  }

  public dispose() {
    this.disable();
  }
}
